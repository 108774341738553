import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { ConfigService } from '../../services/config.service';
import Validation from '../../../utils/validation';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {
  @Input() submitUrl: string;
  @Input() messages: any;
  token: string | undefined;

  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) {
    this.token = undefined;
  }

  fieldTextType = false;
  type: any = 'password';
  isOfType: string;
  useRecaptcha = this.configService.useRecaptcha;
  registrationForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
    acceptRegistrationTerms: new FormControl(),
    acceptPrivacyTerms: new FormControl(),
    acceptInitiative: new FormControl(),
    acceptNews: new FormControl(),
    recaptcha: new FormControl(''),
  });

  ngOnInit(): void {
    this.registrationForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      confirmPassword: new FormControl('', Validators.required),
      acceptRegistrationTerms: new FormControl(false, Validators.requiredTrue),
      acceptPrivacyTerms: new FormControl(false, Validators.requiredTrue),
      acceptInitiative: new FormControl('true', Validators.required),
      acceptNews: new FormControl('true', Validators.required),
      recaptcha: this.useRecaptcha ? new FormControl('', Validators.required) : new FormControl(''),
    },
    {
      validators: [Validation.match('password', 'confirmPassword')]
    });
    this.isOfType = this.configService.isOfType();
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
  }

  get registrationFormControls(){
    return this.registrationForm.controls;
  }

  togglePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  onSubmit(): void {
    // Process checkout data here
    console.log('-- REGISTRATION INPUTS', this.registrationForm.value);
    this.http
      .post(this.configService[this.isOfType + 'RegisterUrl'], this.registrationForm.value)
      .subscribe(
        (response) => {
          console.log(' ON SUBMIT RESPONSE', response);
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
    this.registrationForm.reset();
  }
}
