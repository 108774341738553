import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config.service';
import { MocksService } from 'src/app/shared/services/mocks.service';
import { WebviewService } from 'src/app/shared/services/webview.service';
import Validation from 'src/app/utils/validation';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-timmusic-registration',
  templateUrl: './timmusic-registration.component.html',
  styleUrls: ['./timmusic-registration.component.scss']
})
export class TimmusicRegistrationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {
  }
  mocks: any;
  mocksError: any;
  useMocksButtons = false;
  submitted = false;
  registrationUrl: string;
  placeholder = 'Conferma password';
  appMessages: any;

  ngOnInit(): void {
    this.getMockedData();
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
    this.route.queryParams.subscribe((params) => {
      this.useMocksButtons = params.useMocksButtons === 'true';
      console.log('--- USE MOCKS BUTTONS', this.useMocksButtons);
    });
    this.registrationUrl = this.configService.timmusicRegisterUrl;
  }

  getMockedData() {
    this.mocksService.getMocks().subscribe((data) => {
      this.mocks = data.mocks;
      this.mocksError = data.mocksError;
    });
    console.log('--- MOCKS DATA', this.mocks);
  }

  returnMockMessageToMobile(useCase: any) {
    const MIPWVData = useCase;
    console.log('--- MOCKS DATA', this.mocks, this.mocksError);
    console.log('--- MIPWVData', MIPWVData);
    let methodName = undefined;
    if (MIPWVData.result === 'OK') {
      methodName = 'MIPserviceOnboardingCompleted';
    } else if (MIPWVData.result === 'KO') {
      methodName = 'MIPExecutionKO';
    } else {
      methodName = 'MIPExecutionKO';
    }

    // TODO: GESTIONE ERRORI NEL CASO IN CUI IL METODO NON ESISTA
    if (this.webviewService.isAndroidWebView && window['nativeApp']) {
      window['nativeApp'][methodName](MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON ANDROID WEBVIEW',
        useCase
      );
    }
    if (this.webviewService.isIOSWebView && window['webkit']) {
      window['webkit'].messageHandlers[methodName].postMessage(MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON IOS',
        useCase
      );
    }
    if (this.webviewService.isWindowsWebView && window.external['notify']) {
      window.external['notify'](MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON WINDOWS',
        useCase
      );
    }
  }
}
