import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/services/config.service';
import { MocksService } from 'src/app/shared/services/mocks.service';
import { WebviewService } from 'src/app/shared/services/webview.service';

@Component({
  selector: 'app-timgames-password-reset',
  templateUrl: './timgames-password-reset.component.html',
  styleUrls: ['./timgames-password-reset.component.scss']
})
export class TimgamesPasswordResetComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) { }

  submitUrl: string;
  appMessages: any;

  ngOnInit(): void {
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      console.log('--- App messages', appMessages);

      this.appMessages = appMessages;
    });
    this.submitUrl = this.configService.timgamesPasswordResetnUrl;
  }

}
