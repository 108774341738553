import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebviewService } from 'src/app/shared/services/webview.service';

@Component({
  selector: 'app-timgames-password-recovery-success',
  templateUrl: './timgames-password-recovery-success.component.html',
  styleUrls: ['./timgames-password-recovery-success.component.scss']
})
export class TimgamesPasswordRecoverySuccessComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private webviewService: WebviewService,
  ) { }

  email: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log('--- PARAMS', params);

      this.email = params['email'];
    });
  }

  onOkPressed(){
    alert('close the webview for mobile or do something for web')
    if (this.webviewService.isAndroidWebView && window['nativeApp']) {
      // TODO: close the webview for android
    }
    if (this.webviewService.isIOSWebView && window['nativeApp']) {
      // TODO: close the webview for iOS
    }
  }

}
