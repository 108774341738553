/* tslint:disable */
export const APP_MESSAGES = {
  MMLoginTIMGAMES: {
    LoginHeader: "Inserisci email e password del tuo account <b> TIMGAMES </b>",
    LoginInsertEmail: "Inserisci indirizzo e-mail",
    LoginInsertPwd: "Inserisci password",
    LoginForgotPwd: "Hai dimenticato la password? <br><a href=\"local\">Imposta nuova Password</a>",
    LoginCTA: "<a href=\"local\">Login</a>",
    LoginConditions: "Leggi le <a href=\"local\">condizioni di registrazione</a> ed <a href=\"local\">Informativa Privacy</a> già accettati in fase di registrazione",
    LoginRegistration: "Non sei registrato? <a href=\"local\">Registrati</a>",
    MandatoryField: "Campo obbligatorio",
    InvalidEmail: "Inserisci un indirizzo email valido",
    LoginRetryCTA: "Riprova",
    ResetPwdDescription: "Inserisci la tua e-mail e ti invieremo le istruzioni per impostare una nuova password",
    ResetPwdCTA: "Invia",
    BackCTA: "Indietro"
  },
  MMRegistrationTIMGAMES: {
    Header: "REGISTRAZIONE",
    HeaderTermsAndCondition: "Completa la registrazione per accedere a <b>TIMGAMES</b> tel:+393312121234",
    Description: "Per registrarti, ti verrà richiesto il numero <b> TIM </b>, fisso o mobile, da associare a <b> TIMGAMES </b>",
    InsertEmail: "Inserisci indirizzo e-mail",
    MandatoryField: "Campo obbligatorio",
    InsertPwd: "Inserisci password",
    ConfirmPwd: "Conferma password",
    ShowPwd: "Mostra password",
    Conditions: "Accetto le <a href=\"local\">Condizioni di Registrazione</a>",
    Condition_Yes: "Si",
    Privacy: "Ho preso visione dell’<a href=\"local\">Informativa Privacy</a>",
    Privacy_Yes: "Si",
    Suggestion: "Vuoi avere <a href=\"local\">suggerimenti</a> sui contenuti o sulle iniziative?",
    Suggestion_Yes: "Si",
    Suggestion_No: "No",
    News: "Vuoi essere aggiornato sulle <a href=\"local\">novità</a> ?",
    News_Yes: "Si",
    News_No: "No",
    AvantiCTA: "Avanti",
    PwdMatch: "Le password inserite non coincidono",
    InvalidEmail: "Inserisci un indirizzo email valido",
    PwdCharactersNumbers: "Le password devono essere almeno di 8 caratteri",
    PwdSpecialCharacterAllowed: "La password può contenere solo i caratteri speciali .@$?!",
    PwdCharactersHint: "La password deve essere lunga almeno 8 caratteri"
  },
  MMConciliationTIMGAMES: {
    HeaderErrorLabel: "Attenzione",
    ConfirmButtonLabel: "Conferma",
    BackButtonLabel: "Indietro",
    Description: "Il numero inserito è già associato ad un'altro account TIMGAMES, Puoi confermare questa associazione e ti ricordiamo che il servizio TIMGAMES sarà fruibile solo dal nuovo account e non più dal precedente."
  },
  MMResetPwdTIMGAMES: {
    ResultOKBodyMessage: "Abbiamo inviato una e-mail all’indirizzo: %email% Segui le istruzioni nella e-mail per impostare una nuova password. Controlla che la nostra e-mail non finisca nella cartella di posta indesiderata.",
    OKCTA: "OK",
    Title: "Imposta nuova password",
    InsertPwd: "Inserisci password",
    ConfirmPwd: "Conferma password",
    PwdLenght: "La password deve essere lunga almeno 8 caratteri",
    MandatoryField: "Campo obbligatorio",
    SpecialCharacters: "La password può contenere solo i caratteri speciali .@$?!",
    SamePwd: "Le password inserite non coincidono",
    ResetPwdSuccess: "Cambio password effettuato con successo",
    CTAAccedi: "Accedi",
    CTAConferma: "Conferma"
  },
  MMRegSummaryTIMGAMES: {
    SubscriptionActive: "TIMGAMES attivo",
    SubscriptionInactive: "TIMGAMES non attivo",
    LineAssociatedLabel: "Linea associata",
    EmailLabel: "Email"
  },
  MMRegistrationTIMMUSIC: {
    Header: "REGISTRAZIONE",
    Description: "Inserisci email e password <br> con cui vuoi registrarti",
    InsertEmail: "Inserisci indirizzo e-mail",
    MandatoryField: "Campo obbligatorio",
    InsertPwd: "Inserisci password",
    ConfirmPwd: "Conferma Password",
    PwdRule: "Mostra Password",
    Conditions: "Accetto le <a href=\"local\">Condizioni di Registrazione</a>",
    Condition_Yes: "Si",
    Privacy: "Ho preso visione dell’<a href=\"local\">Informativa Privacy</a>",
    Privacy_Yes: "Si",
    Suggestion: "Vuoi avere <a href=\"local\">suggerimenti</a> sui contenuti o sulle iniziative?",
    Suggestion_Yes: "Si",
    Suggestion_No: "No",
    News: "Vuoi essere aggiornato sulle <a href=\"local\">novità</a> ?",
    News_Yes: "Si",
    News_No: "No",
    AvantiCTA: "Avanti",
    PwdMatch: "Le password inserite non coincidono",
    InvalidEmail: "Inserisci un indirizzo email valido",
    PwdCharactersNumbers: "Le password devono essere almeno di 8 caratteri",
    PwdSpecialCharacterAllowed: "La password può contenere solo i caratteri speciali .@$?!",
    PwdCharactersHint: "La password deve essere lunga almeno 8 caratteri"
  },
  MMRegSummaryTIMMUSIC: {
    SubscriptionActive: "TIMMUSIC attivo",
    SubscriptionInactive: "TIMMUSIC non attivo",
    LineAssociatedLabel: "Linea associata",
    EmailLabel: "Email"
  },
  MMResetPwdTIMMUSIC: {
    ResultOKBodyMessage: "Abbiamo inviato una e-mail all’indirizzo: %email% Segui le istruzioni nella e-mail per impostare una nuova password. Controlla che la nostra e-mail non finisca nella cartella di posta indesiderata.",
    OKCTA: "OK",
    Title: "Imposta nuova password",
    InsertPwd: "Inserisci password",
    ConfirmPwd: "Conferma password",
    PwdLenght: "La password deve essere lunga almeno 8 caratteri",
    MandatoryField: "Campo obbligatorio",
    SpecialCharacters: "La password può contenere solo i caratteri speciali .@$?!",
    SamePwd: "Le password inserite non coincidono",
    ResetPwdSuccess: "Cambio password effettuato con successo",
    CTAAccedi: "Accedi",
    CTAConferma: "Conferma"
  }
}
