import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import Validation from 'src/app/utils/validation';
import { ConfigService } from '../../services/config.service';
import _ from 'lodash';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit {

  @Input() submitUrl: string;
  @Input() messages: any;
  token: string | undefined;

  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) {
    this.token = undefined;
  }

  fieldTextType = false;
  type: any = 'password';
  isOfType: string;
  useRecaptcha = this.configService.useRecaptcha;
  resetPswForm = new FormGroup({
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
    recaptcha: new FormControl(''),
  });

  ngOnInit(): void {
    this.resetPswForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      confirmPassword: new FormControl('', Validators.required),
      recaptcha: this.useRecaptcha ? new FormControl('', Validators.required) : new FormControl(''),
    },
    {
      validators: [Validation.match('password', 'confirmPassword')]
    });
    this.isOfType = this.configService.isOfType();
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
  }

  get resetPswFormControls(){
    return this.resetPswForm.controls;
  }

  togglePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  onSubmit(): void {
    // Process checkout data here
    console.log('-- REGISTRATION INPUTS', this.resetPswForm.value);
    this.http
      .post(this.submitUrl, this.resetPswForm.value)
      .subscribe(
        (response) => {
          this.resetPswForm.reset();
          console.log(' ON SUBMIT RESPONSE', response);
          if(_.get(response, 'resultCode') === 'OK'){
            // TODO: implement success case
            alert('show success message + action');
          } else {
            // TODO: implement error case
            alert('show error message + action');
          }
        },
        (error) => {
          this.resetPswForm.reset();
          // TODO: implement error case
          console.log('ERROR', error);
        }
      );
  }
}
