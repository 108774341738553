import { ModalService } from './../services/modal.service';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  constructor(private configService: ConfigService, public modalService: ModalService) {}
  modal: any;
  modalSubject;
  showLoginModal = false;
  showConciliationModal = false;
  showChangePasswordModal = false;
  showConsentsModal = false;
  modalTypes = [
    'loginModal',
  ]
  appMessages: any;

  ngOnInit(): void {
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
    this.modalService.modal$.subscribe((currentModal) => {
      if (!this.modalTypes?.includes(currentModal?.type) && currentModal?.type) {
        currentModal['type'] = 'default';
      }
      this.modal = currentModal;
    })
    this.modalSubject = this.modalService.modal$;
  }

  executeCallBack(callBack) {
    if (typeof callBack == 'function') {
      callBack();
    }
    this.closeModal();
  }

  closeModal() {
    this.modalSubject.next({})
  }
}
