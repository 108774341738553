import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
})
export class PasswordFieldComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() placeholder = '';

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  public onChange: any = (_) => { /*Empty*/ }
  public onTouched: any = () => { /*Empty*/ }
  public onFocus: any = () => { /*Empty*/ }
  touched = false;
  _value: string = null;
  get value(): any { return this._value; };

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.onTouched(v);
      this.onFocus(v);
    }
  }

  fieldTextType = false;
  type: any = 'password';

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this._value = value;
  }

  registerOnFocus(fn: any) {
    this.onFocus = fn;
    this.onTouched();
  }

  onInput($event: any): void {
    this._value = $event.currentTarget.value;
    this.onChange(this._value);
  }

  togglePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}


