import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebviewService {
  constructor() {}
  // TODO: adapt to the new paramters passed by the launcher
  isWebView = !!this.getQueryVariable('mobiledevice');
  isWebViewDebugMode = this.getQueryVariable('debug') === 'true';
  isIOSWebView = this.getQueryVariable('mobiledevice') === 'ios';
  isWindowsWebView = this.getQueryVariable('mobiledevice') === 'windows';

  isAndroidWebView =
    this.isWebView && !this.isIOSWebView && !this.isWindowsWebView;

  getQueryVariable(variable: any) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return null;
  }
}
