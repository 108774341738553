import { ConfigService } from './../../shared/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import _ from 'lodash'

@Component({
  selector: 'app-external-iframe',
  templateUrl: './external-iframe.component.html',
  styleUrls: ['./external-iframe.component.scss']
})
export class ExternalIframeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,  private configService: ConfigService, public sanitizer: DomSanitizer) { }
  urlParam: any;
  properties = this.configService.properties$;
  urlValue: SafeResourceUrl;

  ngOnInit(): void {
    console.log('--- EXTERNAL ROUTE', this.route);
    this.route.data.subscribe((data) => {
      if (data.url) {
        this.urlParam = data.url;
        this.properties.subscribe((properties) => {

          // TODO: REMOVE MOCK URLS
          _.set(properties, 'legal_privacyinfo_url', 'https://www.timentertainment.it/web/#/?serviceId=TIM_GAMES&section=terms&condition=P2');
          _.set(properties, 'regisration_terms_url', 'https://www.timentertainment.it/web/#/?serviceId=TIM_GAMES&section=terms&condition=P1');
          _.set(properties, 'service_terms_url', 'https://images-timgames.cb.ticdn.it/timgames/Condizioni_Generali_Servizio_TIMGAMESPLUS_Mobile_def_2022_04.pdf');
          if (_.get(properties, this.urlParam)) {
            this.urlValue = this.sanitizer.bypassSecurityTrustResourceUrl(_.get(properties, this.urlParam));
          } else {
            this.router.navigate(['/404']);
          }
        })
      } else {
        this.router.navigate(['/404']);
      }
    });
  }

}
