import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import packageJson from '../../../../package.json';
import { environment } from '../../../environments/environment';
import { APP_MESSAGES } from '../../utils/app-messages';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient, private router: Router) { }

  properties$: BehaviorSubject<any> = new BehaviorSubject({});
  appMessages$: BehaviorSubject<any> = new BehaviorSubject(APP_MESSAGES);
  initializationDone$: BehaviorSubject<any> = new BehaviorSubject(false);
  version = packageJson.version;
  useRecaptcha = this.recaptchaIsActive();
  channel = 'CUBOWEB';
  serviceName = 'CUBOVISION';
  deviceType = 'WEB';
  title = 'Partnership-landing-pages';
  collUrls = [
    "https://servicescoll.timvision.it",
    "https://servicescoll2.timvision.it",
    "https://servicescoll3.timvision.it",
    "https://timvisiontvcoll.tim.it",
    "https://timvisiontvcoll2.tim.it",
    "https://timvisiontvcoll3.tim.it",
  ];
  prodUrls = [
    "https://timvisiontv.tim.it",
    "https://timvision.it"
  ];
  isProd = this.prodUrls.includes(window.location.hostname);
  isColl = this.collUrls.includes(window.location.hostname);
  urlPrefix = (this.isLocalhost && environment.production === false) ? '/api/dev' : '';
  urlProperties = this.urlPrefix + '/TIM/1.1.1/PROD/IT/' + this.channel + '/ITALY/PROPERTIES?deviceType=' + this.deviceType + '&serviceName=' + this.serviceName;
  urlAppMessages = this.urlPrefix + '/TIM/1.1.1/PROD/IT/' + this.channel + '/ITALY/APPMESSAGES?deviceType=' + this.deviceType + '&serviceName=' + this.serviceName;
  avsPlatformParams = this.urlPrefix + '/AVS/besc?channel=' + this.channel + '&providerName=TELECOMITALIA&serviceName=' + this.serviceName;
  avsFullUrl = this.avsPlatformParams;
  ipAuthUrl = this.avsFullUrl + '&action=IpAuthentication&accountDeviceIdType=SERIALNUMBER&accountDeviceVersion=' + this.version + '&accountDeviceModel=' + this.deviceType + '&deviceVendor=' + this.deviceType + '&deviceType=' + this.deviceType + '&appVersion=' + this.version;
  timgamesLoginUrl = this.urlPrefix + '/TIM/1.1.1/PROD/IT/CUBOWEB/ITALY/APPMESSAGES?mock=LOGIN&timgames';
  timmusicLoginUrl = this.urlPrefix + '/TIM/1.1.1/PROD/IT/CUBOWEB/ITALY/APPMESSAGES?mock=LOGIN&timmusic';
  timgamesRegisterUrl = this.urlPrefix + '/TIM/1.1.1/PROD/IT/CUBOWEB/ITALY/APPMESSAGES?mock=REGISTRATION&timgames';
  timmusicRegisterUrl = this.urlPrefix + '/TIM/1.1.1/PROD/IT/CUBOWEB/ITALY/APPMESSAGES?mock=REGISTRATION&timmusic';
  timgamesPasswordRecoveryUrl = this.urlPrefix + 'TIM/TRUSTED/MM/1/IT/MMRESETPWDVALIDATION?token=<token>&serviceId=<TIM_MUSIC or TIM_GAMES>&channelId=<CHANNEL>&txId=<txId>'
  timgamesPasswordResetnUrl = this.urlPrefix + '/TIM/1.1.1/PROD/IT/CUBOWEB/ITALY/APPMESSAGES?mock=PASSWORDRECOVERY';

  // partnerLdServiceUrl = this.urlPrefix + '/AVS/PARTNERLDSERVICE';

  // TODO: DEFINE URL AND EVENTUALLY SET ENVIRONMENT VARIABLE
  partnerLdServiceUrl = 'https://servicescoll3.timvision.it/MMAL/HTTP/PARTNERSHIP/1/IT/PARTNERLDSERVICE?originalUrl=https%3A%2F%2Fmipservicescoll.tim.it%2Fmip-fe%2Fdazn%3Ft%3DAXDENUYSRA6R%26c%3Dmail';
  // https://<mip_domain>/<partnerId>?t=<tinyCode>&c=<commTipology mail|sms>

 getProperties() {
    const req = this.http.get(this.urlProperties);
    req.subscribe(data => {
      this.properties$.next(data);
    });
  }

  getAppMessages() {
    const req = this.http.get(this.urlAppMessages);
    req.subscribe(data => {
      this.appMessages$.next({
        ...APP_MESSAGES,
        ...data['resultObj']
      });
      console.log('--- APP MESSAGES', this.appMessages$.value);
    });
  }

  isLocalhost() {
    return window.location.hostname.includes('localhost')
  };

  isOfType(): string {
    return this.router.url?.split('/')[1];
  }

  recaptchaIsActive() {
    console.log('--- window.location.hostname', window.location.hostname);
    console.log('--- isLocalhost', this.isLocalhost());
    if (this.isLocalhost()) {
      console.log('--- IS LOCALHOST');
      return false;
    }
    else if (this.isColl && environment?.recaptcha?.siteKey) {
      console.log('--- IS COLL');
      return true;
    } else {
      console.log('--- IS PROD');
      return true;
    }
  }

  /* getProperties() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.urlProperties)
        .toPromise()
        .then(
          res => { // Success
            console.log('--- DATA PROPS', res);
            resolve(res);
          }
        );
    });
    return promise;
  } */
}
