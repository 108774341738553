import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {

  constructor(private configService: ConfigService, private http: HttpClient) { }
  termsAndConditionForm = new FormGroup({
    acceptRegistrationTerms: new FormControl(),
    acceptPrivacyTerms: new FormControl(),
    acceptInitiative: new FormControl(),
    acceptNews: new FormControl()
  });
  appMessages: any;

  ngOnInit(): void {
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
    this.termsAndConditionForm = new FormGroup({
      acceptRegistrationTerms: new FormControl(false, Validators.requiredTrue),
      acceptPrivacyTerms: new FormControl(false, Validators.requiredTrue),
      acceptInitiative: new FormControl('true', Validators.required),
      acceptNews: new FormControl('true', Validators.required),
    });
    // this.isOfType = this.configService.isOfType();
  }

  onSubmit(): void {
    // Process checkout data here
    /* console.log('-- REGISTRATION INPUTS', this.registrationForm.value);
    this.http
      .post(this.configService[this.isOfType + 'RegisterUrl'], this.registrationForm.value)
      .subscribe(
        (response) => {
          console.log(' ON SUBMIT RESPONSE', response);
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
    this.registrationForm.reset(); */
  }
}
