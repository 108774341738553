import { ConfigService } from 'src/app/shared/services/config.service';
import { Component, isDevMode, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tim-multimedia-services';

  constructor (private configService: ConfigService) {}

  // TODO: Intercept parameters if we are Tim Games or Tim Music
  currentTheme = 'timgames';

  ngOnInit() {
    this.configService.getAppMessages();
    this.configService.getProperties();
    if (window.location.pathname?.split('/')[1] === 'timmusic') {
      this.currentTheme = 'timmusic'
    }
    if (isDevMode()) {
      console.log('Development!');
    } else {
      console.log('Production!');
      console.log = (...args: string[]) => {};
    }
  }
}
