import { ExternalIframeComponent } from './pages/external-iframe/external-iframe.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimgamesLoginComponent } from './pages/timgames-login/timgames-login.component';
import { TimgamesRegistrationComponent } from './pages/timgames-registration/timgames-registration.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TimmusicLoginComponent } from './pages/timmusic-login/timmusic-login.component';
import { TimmusicRegistrationComponent } from './pages/timmusic-registration/timmusic-registration.component';
import { TimgamesTermsAndConditionComponent } from './pages/timgames-terms-and-condition/timgames-terms-and-condition.component';
import { TimgamesPasswordRecoveryComponent } from './pages/timgames-password-recovery/timgames-password-recovery.component';
import { TimgamesPasswordRecoverySuccessComponent } from './pages/timgames-password-recovery-success/timgames-password-recovery-success.component';
import { TimgamesPasswordResetComponent } from './pages/timgames-password-reset/timgames-password-reset.component';


const routes: Routes = [
  { path: '', component: TimgamesLoginComponent },
  { path: 'timgames/login', component: TimgamesLoginComponent },
  { path: 'timgames/registration', component: TimgamesRegistrationComponent },
  { path: 'timmusic/login', component: TimmusicLoginComponent },
  { path: 'timmusic/registration', component: TimmusicRegistrationComponent },
  { path: 'timgames/profile', component: ProfileComponent },
  { path: 'timgames/passwordrecovery', component: TimgamesPasswordRecoveryComponent },
  { path: 'timgames/passwordrecoverysuccess', component: TimgamesPasswordRecoverySuccessComponent },
  { path: 'timgames/passwordreset', component: TimgamesPasswordResetComponent },
  { path: 'timgames/termsandconditions', component: TimgamesTermsAndConditionComponent },
  { path: 'timmusic/termsandconditions', component: TimgamesTermsAndConditionComponent },

  // TODO: USE APPROPRIATE PARAMETERS (LODASH PATH)
  { path: 'timgames/privacyinfo', component: ExternalIframeComponent, data: {url: 'legal_privacyinfo_url'}},
  { path: 'timgames/registrationterms', component: ExternalIframeComponent, data: {url: 'regisration_terms_url'}},
  { path: 'timgames/serviceterms', component: ExternalIframeComponent, data: {url: 'service_terms_url'}},
  { path: '404', component: PageNotFoundComponent},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
