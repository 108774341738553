import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ModalService {

  constructor() { }
  modal$: BehaviorSubject<any> = new BehaviorSubject({});

  openModal(params) {
    this.modal$.next(params);
  }

}
