import { ConfigService } from '../../shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { WebviewService } from '../../shared/services/webview.service';
import { MocksService } from '../../shared/services/mocks.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-timgames-login',
  templateUrl: './timgames-login.component.html',
  styleUrls: ['./timgames-login.component.scss'],
})
export class TimgamesLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  loginUrl: string;
  LoginMocksOK: any;
  LoginMocksError: any;
  useMocksButtons = false;
  appMessages: any;

  ngOnInit(): void {
    this.getMockedData();
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
    this.route.queryParams.subscribe((params) => {
      this.useMocksButtons = params.useMocksButtons === 'true';
      console.log('--- USE MOCKS BUTTONS', this.useMocksButtons);
    });
    this.loginUrl = this.configService.timgamesLoginUrl;
  }

  getMockedData() {
    this.mocksService.getMocks().subscribe((data) => {
      this.LoginMocksOK = data.LoginMocksOK;
      this.LoginMocksError = data.LoginMocksError;
    });
    console.log('--- MOCKS DATA', this.LoginMocksOK);
  }

  returnMockMessageToMobile(useCase: any, platform: string, methodName: string) {
    const MIPWVData = useCase;

    if (platform === 'android') {
      if (window['nativeApp'] && window['nativeApp'][methodName]) {
        console.log(
          '--- returnMockMessageToMobile - USE CASE ANDROID WITH DATA',
          useCase
        );
        window['nativeApp'][methodName](MIPWVData);
      } else {
        console.log('--- IS NOT POSSIBLE TO CALL window.nativeApp.' +
          methodName +
          ' ON PLATFORM DIFFERENT FROM ANDROID',
          useCase
        );
      }
    }
    else if (platform === 'iOS') {
      if (window['webkit'] &&
        window['webkit'].messageHandlers &&
        window['webkit'].messageHandlers[methodName] &&
        window['webkit'].messageHandlers[methodName].postMessage
      ) {
        console.log(
          '--- returnMockMessageToMobile - USE CASE IOS WITH DATA',
          useCase
        );
        window['webkit'].messageHandlers[methodName].postMessage(MIPWVData);
      } else {
        console.log('--- IS NOT POSSIBLE TO CALL window.webkit.messageHandlers.' +
          methodName +
          '.postMessage' +
          ' ON PLATFORM DIFFERENT FROM IOS',
          useCase
        );
      }
    }
    else {
      window.external['notify'](MIPWVData);
      if (window.external && window.external['notify']) {
        console.log(
          '--- returnMockMessageToMobile - USE CASE WINDOWS WITH DATA',
          useCase
        );
        window.external['notify'](MIPWVData);
      } else {
        console.log('--- IS NOT POSSIBLE TO CALL window.external.notify ON PLATFORM DIFFERENT FROM WINDOWS',
          useCase
        );
      }
    }

    /* if (this.webviewService.isAndroidWebView && window['nativeApp']) {
      window['nativeApp'][methodName](MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON ANDROID WEBVIEW',
        useCase
      );
    }
    if (this.webviewService.isIOSWebView && window['webkit']) {
      window['webkit'].messageHandlers[methodName].postMessage(MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON IOS',
        useCase
      );
    }
    if (this.webviewService.isWindowsWebView && window.external['notify']) {
      window.external['notify'](MIPWVData);
      console.log(
        '--- returnMockMessageToMobile - USE CASE BUTTON WINDOWS',
        useCase
      );
    } */
  }

}
