import { ConfigService } from './config.service';
import { MocksService } from './mocks.service';
import { HttpHandler, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import _ from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class Interceptor implements HttpInterceptor {
  constructor(private mocksService: MocksService, private configService: ConfigService) {

  }
  mocks: any;

  getMockedData () {
    this.mocksService.getMocks().subscribe(data => {
      this.mocks = data;
    });
    console.log('--- MOCKS DATA INTERCEPTOR', this.mocks);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.getMockedData();

    // LOGIN
    if ((request.url === this.configService.timgamesLoginUrl || request.url === this.configService.timmusicLoginUrl) && environment.production === false && environment.useMocks === true) {
      console.log('--- INSIDE INTERCEPT LOGIN');
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            let mutableResponse = _.cloneDeep(event);
            mutableResponse.status = 500;
            mutableResponse.body = this.mocks.loginMock;
            console.log('--- INTERCEPTOR RESPONSE LOGIN', mutableResponse)
            return mutableResponse;
          }
        })
      );
    }

    // REGISTRATION
    if ((request.url === this.configService.timgamesRegisterUrl || request.url === this.configService.timmusicRegisterUrl) && environment.production === false && environment.useMocks === true) {
      console.log('--- INSIDE INTERCEPT REGISTER');
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            let mutableResponse = _.cloneDeep(event);
            mutableResponse.status = 500;
            mutableResponse.body = this.mocks.registrationMock;
            console.log('--- INTERCEPTOR RESPONSE REGISTER', mutableResponse)
            return mutableResponse;
          }
        })
      );
    }


    // PASSWORD RECOVERY
    if (request.url === this.configService.timgamesPasswordRecoveryUrl && environment.production === false && environment.useMocks === true) {
      console.log('--- INSIDE INTERCEPT PASSWORD RECOVERY');
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            let mutableResponse = _.cloneDeep(event);
            console.log('--- MUTABLE RESPONSE', mutableResponse);

            // mutableResponse.status = 500;
            mutableResponse.body = this.mocks.passwordRecoveryOK;
            return mutableResponse;
          }
        })
      );
    }

    return next.handle(request);
  }
}
