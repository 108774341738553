import { ConfigService } from '../../shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { WebviewService } from '../../shared/services/webview.service';
import { MocksService } from '../../shared/services/mocks.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-timgames-password-recovery',
  templateUrl: './timgames-password-recovery.component.html',
  styleUrls: ['./timgames-password-recovery.component.scss']
})
export class TimgamesPasswordRecoveryComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  submitUrl: string;
  mocks: any;
  mocksError: any;
  useMocksButtons = false;
  appMessages: any;

  ngOnInit(): void {
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
    this.route.queryParams.subscribe((params) => {
      this.useMocksButtons = params.useMocksButtons === 'true';
      console.log('--- USE MOCKS BUTTONS', this.useMocksButtons);
    });
    this.submitUrl = this.configService.timgamesPasswordRecoveryUrl;
  }

  goBack(){
    alert('go back needs to be implemented')
  }

}
