export const environment = {
  production: false,
  useMocks: true,
  useCaptcha: false,
  apiUrl: 'http://localhost:4200/',
  recaptcha: {
    siteKey: '6Le8ZVsUAAAAAEy2Dq5N8vLnQ5quKnhPPYTfnkDL',
  },
};

