import { ModalService } from './shared/services/modal.service';
import { ConfigService } from './shared/services/config.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MocksService } from './shared/services/mocks.service';
import { Interceptor } from './shared/services/interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { TimgamesLoginComponent } from './pages/timgames-login/timgames-login.component';
import { TimgamesRegistrationComponent } from './pages/timgames-registration/timgames-registration.component';
import { ExternalIframeComponent } from './pages/external-iframe/external-iframe.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PasswordFieldComponent } from './shared/components/password-field/password-field.component';
import { LoginFormComponent } from './shared/components/login-form/login-form.component';
import { TimmusicLoginComponent } from './pages/timmusic-login/timmusic-login.component';
import { TimmusicRegistrationComponent } from './pages/timmusic-registration/timmusic-registration.component';
import { PasswordChangeComponent } from './pages/profile/partials/password-change/password-change.component';
import { RegistrationFormComponent } from './shared/components/registration-form/registration-form.component';
import { TermsAndConditionComponent } from './shared/components/terms-and-condition/terms-and-condition.component';
import { TimgamesTermsAndConditionComponent } from './pages/timgames-terms-and-condition/timgames-terms-and-condition.component';
import { TimmusicTermsAndConditionComponent } from './pages/timmusic-terms-and-condition/timmusic-terms-and-condition.component';
import { TimgamesPasswordRecoveryComponent } from './pages/timgames-password-recovery/timgames-password-recovery.component';
import { PasswordRecoveryFormComponent } from './shared/components/password-recovery-form/password-recovery-form.component';
import { TimgamesPasswordRecoverySuccessComponent } from './pages/timgames-password-recovery-success/timgames-password-recovery-success.component';
import { TimgamesPasswordResetComponent } from './pages/timgames-password-reset/timgames-password-reset.component';
import { PasswordResetFormComponent } from './shared/components/password-reset-form/password-reset-form.component';
import { ModalsComponent } from './shared/modals/modals.component';

@NgModule({
  declarations: [
    AppComponent,
    TimgamesLoginComponent,
    TimmusicLoginComponent,
    TimgamesRegistrationComponent,
    ExternalIframeComponent,
    PageNotFoundComponent,
    ProfileComponent,
    PasswordFieldComponent,
    LoginFormComponent,
    TimmusicRegistrationComponent,
    PasswordChangeComponent,
    RegistrationFormComponent,
    TermsAndConditionComponent,
    TimgamesTermsAndConditionComponent,
    TimmusicTermsAndConditionComponent,
    TimgamesPasswordRecoveryComponent,
    PasswordRecoveryFormComponent,
    TimgamesPasswordRecoverySuccessComponent,
    TimgamesPasswordResetComponent,
    PasswordResetFormComponent,
    ModalsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    FormsModule
  ],
  providers: [MocksService, ConfigService, ModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment?.recaptcha?.siteKey,
      } as RecaptchaSettings,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
