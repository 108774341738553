import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { MocksService } from '../../services/mocks.service';
import { WebviewService } from '../../services/webview.service';
import _ from 'lodash';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from '@angular/forms';

@Component({
  selector: 'app-password-recovery-form',
  templateUrl: './password-recovery-form.component.html',
  styleUrls: ['./password-recovery-form.component.scss']
})
export class PasswordRecoveryFormComponent implements OnInit {
  @Input() submitUrl: string;
  @Input() messages: any;

  token: string | undefined;
  fieldTextType = false;
  type: any = 'password';
  useRecaptcha = this.configService.useRecaptcha;
  passwordRecoveryForm = new FormGroup({
    username: new FormControl(''),
    recaptcha: new FormControl(''),
  });
  queryParams: any = {}

  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
  ) {
    this.token = undefined;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log('--- PARAMS',params); // { orderby: "price" }
        this.queryParams = params;
      }
    );
    console.log('--- this.queryParams', this.queryParams);

    this.passwordRecoveryForm = new FormGroup({
      username: new FormControl((this.queryParams.username || ''), [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.email,
      ]),
      recaptcha: this.useRecaptcha
        ? new FormControl('', Validators.required)
        : new FormControl(''),
    });
  }

  onSubmit(): void {
    // Process checkout data here
    console.log('-- PASSWORD RECOVERY INPUTS', this.passwordRecoveryForm.value);
    this.http.post(this.submitUrl, this.passwordRecoveryForm.value).subscribe(
      (response) => {
        console.log(' ON PASSWORD RECOVERY RESPONSE', response, this.passwordRecoveryForm.value);
        if(_.get(response, 'resultCode') === 'OK'){
          this.router.navigate(
            [this.router.url?.split('/')[1]+'/passwordrecoverysuccess'],
            { queryParams: { email: this.passwordRecoveryForm.value?.username}}
          );
          this.passwordRecoveryForm.reset();
        } else {
          console.log('ON PASSWORD RECOVERY ERROR', response);
          //  TODO: ERROR MODAL MESSAGE
          alert('show error modal')
        }
      },
      (error) => {
        console.log('ON PASSWORD RECOVERY ERROR', error);
        //  TODO: ERROR MODAL MESSAGE
        alert('show error modal')
      }
    );

  }

}
