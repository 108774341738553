import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-timgames-terms-and-condition',
  templateUrl: './timgames-terms-and-condition.component.html',
  styleUrls: ['./timgames-terms-and-condition.component.scss']
})
export class TimgamesTermsAndConditionComponent implements OnInit {

  constructor(private configService: ConfigService) { }

  appMessages: any;

  ngOnInit(): void {
    this.configService.appMessages$.pipe(first()).subscribe((appMessages) => {
      this.appMessages = appMessages;
    });
  }

}
