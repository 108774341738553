import { ConfigService } from './config.service';
// src/app/user.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import * as mockedData from '../../../app/mocks.json';

@Injectable({
  providedIn: 'root',
})
export class MocksService {
  data = mockedData;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  getMocks(): Observable<any> {
    console.log('--- MOCKS', this.data)
    return of(this.data);
  }
}
