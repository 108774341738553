import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { MocksService } from '../../services/mocks.service';
import { WebviewService } from '../../services/webview.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import _ from 'lodash';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() submitUrl: string;
  @Input() messages: any;
  token: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private mocksService: MocksService,
    private webviewService: WebviewService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: ModalService
  ) {
    this.token = undefined;
  }

  fieldTextType = false;
  type: any = 'password';
  useRecaptcha = this.configService.useRecaptcha;
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    recaptcha: new FormControl(''),
  });

  ngOnInit(): void {
    console.log('--- RECAPTCHA LOGIN', this.useRecaptcha);
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      recaptcha: this.useRecaptcha
        ? new FormControl('', Validators.required)
        : new FormControl(''),
    });
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
  }

  get loginFormControls(){
    return this.loginForm.controls;
  }

  togglePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  onSubmit(): void {
    console.log('-- LOGIN INPUTS', this.loginForm.value);
    this.http.post(this.submitUrl, this.loginForm.value).subscribe(
      (response) => {
        console.log(' ON SUBMIT RESPONSE', response);
        if (_.get(response, 'resultCode') === 'OK') {
          console.log('--- ON RESPONSE OK');
        } else {
          // TODO: GET ERROR MESSAGES FROM RESPONSE
          this.modalService.openModal({
            type: 'loginModal',
            icon: '',
            title: '',
            message: 'Email o password errati.',
            actions: [
              {
                label: this.messages?.LoginRetryCTA,
                callBack: () => {
                  alert('--- OK ACTION');
                }
              }
            ],
          })
        }
      },
      (error) => {
        console.log('ERROR', error);
      }
    );
    this.loginForm.reset();
  }
}
